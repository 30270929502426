<template>
  <div class="keywords-input-selector">
    <label for="groups">{{ label }}</label>
    <multiselect
        id="groups"
        :value="value"
        @input="$emit('input', $event)"
        @tag="addTag"
        :options="options"
        :multiple="true"
        :taggable="true"
        :close-on-select="false"
        :clear-on-select="false"
        :preserve-search="true"
        label="name"
        tag-placeholder="Add this as new tag"
        track-by="name"
        placeholder="Keyword suchen oder selber erstellen"
        select-label="Mit Enter auswählen"
        selectedLabel="Ausgewählt"
        autocomplete="off"
    >
    </multiselect>
    <slot name="description">
      <small class="form-text text-muted w-100 mb-3">{{ description }}</small>
    </slot>
  </div>
</template>

<script>
import axios from "axios";
import commons from "@/commons";

export default {
  name: "KeywordsInput",
  props: {
    label: {
      type: String,
      default: 'Keywords'
    },
    value: {
      type: Array,
      required: true
    },
    options: {
      type: Array,
      default: () => []
    },
    description: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dataOptions: [...this.options]
    }
  },
  mounted() {

  },
  methods: {
    addTag (newTag) {
      const tag = {
        name: newTag
      }
      this.dataOptions.push(tag)
      this.value.push(tag)
    }
  }
}
</script>

<style scoped>

</style>
