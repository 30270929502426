<template>
  <form ref="socialMediaPostForm">
    <CRow>
      <CCol sm="4">
        <CInput
          id="internal-name-input"
          label="Beitragstitel"
          name="name"
          v-model="socialMediaPostName"
          placeholder="z.B. Wochenendtipps KW 51"
          description="Geben Sie einen internen Titel für diesen Beitrag ein. Dieser Titel dient nur der internen Organisation und wird nicht öffentlich angezeigt."
          autocomplete="off"
          required
          v-c-tooltip="'Erstellen Sie einen eindeutigen Titel für die interne Nutzung.'"
        />
      </CCol>
      <CCol sm="4">
        <SocialMediaSelector
          v-model="connectionSelected"
          description="Wählen Sie die Social Media-Plattformen aus, auf denen dieser Beitrag geteilt werden soll."
          v-c-tooltip="'Wählen Sie eine oder mehrere Plattformen aus, auf denen Ihr Beitrag veröffentlicht werden soll.'"
        />
      </CCol>
      <CCol sm="4">
        <DateTimePicker
          id="publish-at-picker"
          class="prt-social-media-date-time-picker"
          label="Veröffentlichungszeitpunkt"
          description="Legen Sie fest, wann Ihr Beitrag veröffentlicht werden soll - Datum und Uhrzeit."
          :horizontal="false"
          v-model="publishAt"
          v-c-tooltip="'Wählen Sie einen spezifischen Zeitpunkt für die Veröffentlichung Ihres Beitrags.'"
        />
      </CCol>
    </CRow>

    <!-- KI Felder -->
    <CRow v-if="ai.active">
      <CCol sm="12">
        <CCard class="gpt-notice">
          <CCardBody>
            <div class="toggle-section">
              <p v-if="ai.active">
                Der KI-Assistent ist aktiviert. Nutzen Sie ihn, um effektivere Beiträge zu erstellen.
              </p>
              <CButton @click="toggleAiMode" color="secondary">
                Wechsel zu Manuell
              </CButton>
            </div>
            <!-- KI-Formularfelder -->
            <CRow class="content-row">
              <CCol sm="4">
                <KeywordsInput
                  id="content-blocks-input"
                  label="Content-Bausteine*"
                  v-model="ai.keywords"
                  :options="ai.options"
                  placeholder="Schlüsselbegriffe hinzufügen"
                  description="Definieren Sie Schlüsselbegriffe und Themen für Ihren Beitrag. Mindestens 2 Schlüsselbegriffe."
                  v-c-tooltip="'Listen Sie Schlüsselwörter auf, um den Inhalt und Fokus Ihres Beitrags zu definieren.'"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  id="headline-input"
                  label="Überschrift"
                  v-model="socialMediaPostHeadline"
                  placeholder="Überschrift Ihres Beitrags"
                  v-c-tooltip="'Dies hilft der KI, die Essenz Ihres Beitrags zu verstehen und relevanteren Inhalt zu generieren.'"
                />
              </CCol>
            </CRow>

            <CRow class="mb-3">
              <CCol sm="12">
                <CTextarea
                  id="main-text-textarea"
                  label="Haupttext"
                  v-model="socialMediaPostMainText"
                  placeholder="Beschreiben Sie, worum es in Ihrem Beitrag gehen soll..."
                  rows="4"
                  v-c-tooltip="'Verfassen Sie den Hauptinhalt Ihres Beitrags. Seien Sie klar und prägnant.'"
                />
              </CCol>
            </CRow>

            <CRow class="mb-3">
              <CCol sm="6">
                <CInput
                  id="cta-input"
                  label="Handlungsaufforderung"
                  v-model="socialMediaPostCTA"
                  placeholder="z.B. Besuchen Sie unsere Website!"
                  description="Ermutigen Sie Ihre Leser zu einer Aktion, z.B. Ihrer Website einen Besuch abzustatten."
                  v-c-tooltip="'Formulieren Sie eine klare Aufforderung, um Ihre Leser zu einer Aktion zu bewegen.'"
                />
              </CCol>

              <CCol sm="6">
                <CSelect
                  id="tone-choice-select"
                  label="Tonwahl"
                  description="Wählen Sie den Ton, der die gewünschte Stimmung und Wirkung Ihres Beitrags vermittelt."
                  v-model="ai.voiceStyle"
                  :options="[
                    {label: 'Energisch', value: 'ENERGETIC'},
                    {label: 'Aufregend', value: 'EXCITING'},
                    {label: 'Enthusiastisch', value: 'ENTHUSIASTIC'},
                    {label: 'Freundlich', value: 'FRIENDLY'},
                    {label: 'Informativ', value: 'INFORMATIONAL'},
                    {label: 'Lustig', value: 'FUNNY'},
                    {label: 'Professionell', value: 'PROFESSIONAL'},
                    {label: 'Lässig', value: 'CASUAL'}
                  ]"
                  v-c-tooltip="'Bestimmen Sie, wie Ihr Beitrag wahrgenommen werden soll, um die gewünschte Resonanz zu erzielen.'"
                />
              </CCol>
            </CRow>

            <CRow class="mb-3">
              <CCol sm="12">
                <CTextarea
                  id="additional-info-textarea"
                  label="Zusätzliche Informationen"
                  v-model="socialMediaPostAdditionalInfo"
                  rows="4"
                  placeholder="Weitere Details wie Angebote, Kontaktinformationen oder Veranstaltungen..."
                  description="Geben Sie zusätzliche Informationen an, die für Ihren Beitrag relevant sein könnten."
                  v-c-tooltip="'Nutzen Sie diesen Bereich für zusätzliche Details, die Ihr Publikum interessieren könnten.'"
                />
              </CCol>
            </CRow>

            <div class="d-flex align-left align-items-center">
              <CButton v-if="!ai.processing" :disabled="ai.generateBtnDisabled" @click="generateByAiClickHandler" color="warning">
                Generieren
              </CButton>
              <CButton v-else color="primary">
                Wird generiert...
              </CButton>
              <DoubleBounceLoader v-if="ai.processing" width="20px" height="20px" style="margin-left: 8px"/>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- Manuelle Felder -->
    <CRow v-if="!ai.active">
      <CCol sm="12">
        <CTextarea
          id="main-text-textarea"
          label="Haupttext"
          v-model="socialMediaPostMainText"
          placeholder="Details Ihres Themas mit persönlicher Note teilen..."
          rows="4"
          v-c-tooltip="'Verfassen Sie den Hauptinhalt Ihres Beitrags. Seien Sie klar und prägnant.'"
        />
      </CCol>
    </CRow>

    <!-- Button zur Rückkehr zu KI -->
    <CRow v-if="!ai.active">
      <CCol>
        <CButton @click="toggleAiMode" color="secondary" style="margin-top: 10px;">
          Zurück zu KI
        </CButton>
      </CCol>
    </CRow>
      
    <!-- Image Upload and Search -->
    <CRow class="mt-3">
        <CCol sm="12">
          <ImageUploadAndSearch
            :firstKeyword="ai.keywords[0]?.name"
            @image-upload="handleImageUpload"
            @image-select="handleImageSelect"
            @image-remove="handleImageRemove"
          />
        </CCol>
      </CRow>

    <CRow class="mt-3">
      <CCol>
        <CButton v-if="!processing" @click="clickHandler" color="success">
          {{ btnText }}
        </CButton>
        <CButton v-else color="primary">
          Wird verarbeitet...
        </CButton>
        <CButton v-if="enableDelete" @click="deleteHandler" color="danger" style="margin-left: 4px">
          Löschen
        </CButton>
      </CCol>
    </CRow>

    <!-- Popup für KI-generierten Inhalt -->
    <div v-if="showAiContentPopup" class="ai-content-popup">
      <div class="ai-content-popup-inner">
        <h5>KI-generierter Inhalt</h5>
        <CTextarea
          v-model="socialMediaContent"
          rows="6"
          placeholder="Der KI-generierte Inhalt wird hier angezeigt und kann bearbeitet werden..."
        />
        <div class="popup-actions">
          <CButton @click="acceptAiContent" color="success">Übernehmen</CButton>
          <CButton @click="closeAiContentPopup" color="secondary">Schließen</CButton>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import moment from "moment";
import { CInput, CTextarea, CSelect, CButton, CRow, CCol } from '@coreui/vue';
import SocialMediaSelector from "@/components/SocialMediaSelector.vue";
import ImageUploadAndSearch from "@/components/ImageUploadAndSearch.vue";
import DateTimePicker from "@/components/DateTimePicker.vue";
import DoubleBounceLoader from "@/components/loaders/DoubleBounceLoader.vue";
import KeywordsInput from "@/components/KeywordsInput.vue";

export default {
  name: 'SocialMediaPostForm',
  components: {
    CInput,
    CTextarea,
    CSelect,
    CButton,
    CRow,
    CCol,
    SocialMediaSelector,
    ImageUploadAndSearch,
    DateTimePicker,
    DoubleBounceLoader,
    KeywordsInput
  },
  props: {
    passedData: {
      type: Object,
      default: () => ({ id: 0 })
    },
    btnText: {
      type: String,
      required: true
    },
    enableDelete: Boolean,
    processing: Boolean
  },
  data() {
    return {
      ai: {
        active: true,
        keywords: [],
        options: [
          'Produktlaunch',
          'Dienstleistungsqualität',
          'Kundenerfahrung',
          'Nachhaltigkeit',
          'Technologieinnovation',
          'Markteinführung',
          'Kundenbindung',
          'Branchentrends',
          'Wettbewerbsvorteil',
          'Veranstaltung',
          'Community-Engagement',
          'Neue Filiale'
        ].map(str => ({ name: str })),
        useEmojis: true,
        voiceStyle: "ENERGETIC",
        processing: false,
        generateBtnDisabled: true,
        generatedContent: ''
      },
      showAiContentPopup: false,
      showAiContentButtons: false, // Hinzugefügt
      isGeneratingAlternative: false, // Hinzugefügt
      id: this.passedData.id || 0,
      connectionSelected: this.passedData.connection || null,
      socialMediaPostName: this.passedData.name || '',
      socialMediaContent: this.passedData.content || '',
      socialMediaPostHeadline: '',
      socialMediaPostMainText: '',
      socialMediaPostHashtags: [],
      socialMediaPostCTA: '',
      socialMediaPostAdditionalInfo: '',
      socialMediaImage: this.passedData.imageUrl || '',
      socialMediaImageTitle: '',
      publishAt: this.passedData.publishAt || moment().add(2, 'days').toISOString(),
      searchQuery: '',
      unsplashPhotos: [],
      currentPage: 1,
      photosPerPage: 10,
      selectedPhoto: null,
      searched: false
    }
  },
  mounted() {
    if (this.passedData) {
      setTimeout(() => this.resizeTextarea(), 1);
    }
  },
  watch: {
    socialMediaContent() {
      this.resizeTextarea();
    },
    'ai.keywords': function (newVal) {
      this.ai.generateBtnDisabled = !Array.isArray(newVal) || newVal.length < 2;
    }
  },
  methods: {
    updatePhoto(photoUrl) {
      this.socialMediaImage = photoUrl;
    },
    updateImageTitle(title) {
      this.socialMediaImageTitle = title;
    },
    toggleAiMode() {
      this.ai.active = !this.ai.active;
      this.showAiContentButtons = false; // Zurücksetzen der Buttons
    },
    generateByAiClickHandler() {
      this.ai.processing = true;
      const keywords = _.map(this.ai.keywords, 'name');
      const data = {
        useEmojis: this.ai.useEmojis,
        voiceStyle: this.ai.voiceStyle,
        keywords: [...keywords],
        title: this.socialMediaPostHeadline,
        mainContent: this.socialMediaPostMainText,
        notes: this.socialMediaPostAdditionalInfo,
        cta: this.socialMediaPostCTA
      };
      axios.post('/socialMedia/generateByAi', data)
        .then(response => {
          this.$toaster.makeToast('Erfolgreich!', 'Beitrag wurde erfolgreich mit KI generiert.');
          this.socialMediaContent = response.data.result;
          this.showAiContentPopup = true;
          const firstKeyword = keywords[0] || '';
          this.searchQuery = firstKeyword;
          this.autoSearchUnsplashPhotos(firstKeyword);
        })
        .catch(err => {
          this.$toaster.makeToast('Fehler!', 'Social Media Inhalt konnte nicht generiert werden: ' + err.toString());
        })
        .finally(() => {
          this.ai.processing = false;
        });
    },
    acceptAiContent() {
      this.socialMediaPostMainText = this.socialMediaContent;
      this.ai.active = false;
      this.showAiContentPopup = false;
      this.showAiContentButtons = true;
    },
    closeAiContentPopup() {
      this.showAiContentPopup = false;
    },
    clickHandler() {
      if (this.$refs.socialMediaPostForm.reportValidity()) {
        if (this.connectionSelected === null) {
          this.$toaster.makeToast('Fehler!', 'Bitte wählen Sie eine Social Media Verbindung aus...');
          return;
        }
        const data = {
          name: this.socialMediaPostName,
          publishAt: this.publishAt,
          imageUrl: this.socialMediaImage,  // Bild-URL hier korrekt setzen
          content: this.socialMediaPostMainText,
          connectionId: this.connectionSelected.id
        };
        axios.post('/socialMedia/posts', data)
          .then(() => {
            this.$router.push({ name: 'SocialMediaPosts', params: { saved: '1' } });
            this.$toaster.makeToast('Erfolgreich!', '<b>Social Media Beitrag wurde erfolgreich angelegt!</b>');
          })
          .catch(err => {
            if (err.response) {
              this.$toaster.makeToast('Oops!', '<b>' + err.response.data.code + '</b><br>' + err.response.data.message);
            } else {
              this.$toaster.makeToast('Error!', err.message);
            }
          })
          .finally(() => {
            this.processing = false;
          });
      }
    },
    deleteHandler() {
      this.$modal.showModal(
        'delete',
        null,
        [`<b>Social Media Beitrag</b> (${this.passedData.name})`],
        async () => {
          try {
            let resp = await axios.delete(`/socialMedia/posts/${this.id}`);
            this.$toaster.makeToast('Erfolgreich!', 'Dieser Social Media Beitrag wurde erfolgreich gelöscht.');
            this.$router.push({ name: 'SocialMediaPosts', params: { saved: '1' } });
          } catch (e) {
            this.$toaster.makeToast('Fehler!', 'Der Beitrag konnte nicht gelöscht werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.');
          }
        }
      );
    },
    resizeTextarea() {
      const textareaDiv = this.$refs.socialMediaContent;
      if (textareaDiv && textareaDiv.$el) {
        const textarea = textareaDiv.$el.querySelector('textarea');
        if (textarea) {
          textarea.style.height = "1px";
          textarea.style.height = (15 + textarea.scrollHeight) + "px";
        }
      }
    },
    searchUnsplashPhotos() {
      axios.get(`/api/unsplash/photos`, {
        params: {
          query: this.searchQuery,
          page: this.currentPage,
          per_page: this.photosPerPage
        }
      })
      .then(response => {
        this.unsplashPhotos = Array.isArray(response.data.results) ? response.data.results : [];
        this.searched = true;
      })
      .catch(error => {
        console.error('Fehler beim Abrufen der Unsplash-Fotos:', error);
        this.searched = true;
      });
    },
    autoSearchUnsplashPhotos(keyword) {
      this.searchQuery = keyword;
      this.searchUnsplashPhotos();
    },
    changePage(pageNumber) {
      if (pageNumber < 1) return;
      this.currentPage = pageNumber;
      this.searchUnsplashPhotos();
    },
    selectUnsplashPhoto(photo) {
      this.selectedPhoto = photo;
      this.updatePhoto(photo.urls.small);
      this.updateImageTitle(photo.alt_description);
      this.$toaster.makeToast('Foto ausgewählt', 'Das Foto wurde erfolgreich ausgewählt.');
    },
    handleImageUpload(imageUrl) {
      this.socialMediaImage = imageUrl;  // Bild-URL hier korrekt setzen
    },
    handleImageSelect(imageUrl) {
      this.socialMediaImage = imageUrl;  // Bild-URL hier korrekt setzen
    },
    handleImageRemove() {
      this.socialMediaImage = '';
    },
    generateAlternativeContent() {
      this.isGeneratingAlternative = true;
      const requestData = {
        useEmojis: true, 
        voiceStyle: "INFORMATIONAL",
        keywords: this.ai.keywords.map(k => k.name),
        title: this.socialMediaPostHeadline,
        mainContent: this.socialMediaPostMainText,
        notes: this.socialMediaPostAdditionalInfo,
        cta: this.socialMediaPostCTA,
        generateAlternative: true
      };

      axios.post('/socialMedia/generateByAi', requestData)
        .then(response => {
          this.socialMediaContent = response.data.result || 'Der KI-generierte Inhalt konnte nicht abgerufen werden.';
        })
        .catch(err => {
          this.$toaster.makeToast('Fehler!', 'Fehler beim Generieren des Inhalts: ' + err.response.data.message);
      })
      .finally(() => {
        this.isGeneratingAlternative = false;
      });
  },
  openAiTool() {
    this.$emit('open-ai-tool');
    console.log('KI-Tool öffnen');
  },
}
};
</script>

<style scoped>
/* Popup für KI-generierten Inhalt */
.ai-content-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  background: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.ai-content-popup-inner {
  padding: 20px;
}

.popup-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.popup-actions CButton {
  margin-left: 10px;
}

.upload-and-search {
  display: flex;
  gap: 20px;
  align-items: center;
}

.upload-section, .search-section {
  flex: 1;
}

.search-bar {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.search-bar input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.search-bar button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.unsplash-photos {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

.photo-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
  height: 350px;
}

.photo-item img {
  max-width: 100%;
  height: 200px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 10px;
}

.photo-info {
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.select-btn {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #05b9ae;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.pagination button {
  padding: 10px 20px;
  background-color: #005f5b;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.pagination span {
  display: flex;
  align-items: center;
}

.double-bounce-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.double-bounce-loader > div {
  width: 20px;
  height: 20px;
  background-color: #007bff;
  border-radius: 100%;
  animation: bounce 2s infinite ease-in-out;
}

.double-bounce-loader .double-bounce2 {
  animation-delay: -1s;
}

@keyframes bounce {
  0%, 100% { transform: scale(0); }
  50% { transform: scale(1); }
}
</style>
